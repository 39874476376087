<template>
  <b-card>
    <b-form>
      <b-form-group label="Tên chương trình">
        <b-form-input v-model="form.name"></b-form-input>
      </b-form-group>
      <b-form-checkbox
        v-model="form.is_first_time_program"
        :value="2"
        :unchecked-value="1"
        >Đây là Chương trình nạp điểm cho thành viên mới</b-form-checkbox
      >
      <b-form-group label="Thời hạn sử dụng" class="mt-2">
        <b-form-checkbox
          v-model="form.is_specifically_time"
          :value="true"
          :unchecked-value="false"
          >Chọn thời hạn cụ thể</b-form-checkbox
        >
        <b-form-group class="mt-1">
          <div class="flex items-center gap-3">
            <b-form-datepicker
              placeholder="Chọn ngày bắt đầu"
              v-model="form.from_date"
              :locale="$i18n.locale"
              v-bind="$getCalendarLabel($i18n.locale)"
            ></b-form-datepicker>
            <b-form-select
              v-model="form.duration"
              :options="durationOptions"
              v-if="!form.is_specifically_time"
            ></b-form-select>
            <b-form-datepicker
              v-else
              placeholder="Chọn ngày kết thúc"
              v-model="form.to_date"
              :locale="$i18n.locale"
              v-bind="$getCalendarLabel($i18n.locale)"
            ></b-form-datepicker>
          </div>
        </b-form-group>
      </b-form-group>
      <b-form-group label="Số tiền nạp">
        <div class="flex items-center gap-3">
          <b-input-group required>
            <template #append>
              <b-input-group-text>VND</b-input-group-text>
            </template>
            <b-form-input
              v-model="form.moneyValue"
              @input="handleChangeMoneyValue"
            ></b-form-input>
          </b-input-group>
          <img src="/icons/icon-equivalent.svg" alt />
          <b-input-group required>
            <template #append>
              <b-input-group-text>Điểm</b-input-group-text>
            </template>
            <b-form-input
              v-model="pointsValue.round"
              :disabled="true"
            ></b-form-input>
          </b-input-group>
        </div>
      </b-form-group>
      <!-- <div class="mt-1">
        <b-form-group label="Số điểm mỗi nhân sự nhận được">
          <b-input-group required>
            <template #append>
              <b-input-group-text>Điểm</b-input-group-text>
            </template>
            <b-form-input
              v-model="form.memberPoints"
              @input="handleChangeDoctorPoints"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <div>
          <div class="flex justify-between items-end">
            <h5 class="mb-0 flex" style="align-items: end">
              {{ $t("Danh sách Nhân sự") }}
            </h5>
            <b-button
              variant="primary"
              class="w-fit"
              @click="handleAddPerson"
              >{{ $t("Thêm nhân sự") }}</b-button
            >
          </div>
          <b-table
            striped
            hover
            responsive
            :items="selectedMembers"
            :fields="fieldsTable"
            class="mt-2 mb-3"
            show-empty
            center
          >
            <template #empty>
              <div class="text-center">{{ $t("Chưa chọn nhân sự") }}</div>
            </template>
            <template #cell(index)="data">
              <div class="">{{ data.index + 1 }}</div>
            </template>
          </b-table>
        </div>
      </div>-->
    </b-form>
    <div class="flex justify-content-end gap-3 mt-2">
      <b-button
        variant="outline-primary"
        @click="$router.push({ name: 'deposit' })"
        >Huỷ</b-button
      >
      <b-button @click="handleDepositPoints" variant="primary" class="w-fit"
        >Lưu</b-button
      >
    </div>
  </b-card>
</template>
<script>
import appUtils from "@/utils/appUtils";
import { EXPIRED_RULE_CREDIT, HODO_CREDIT_TYPE } from "@/utils/constant";
import { omit } from "lodash";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: "CreateTopUpPointTransaction",
  data() {
    return {
      form: {
        moneyValue: null,
        memberPoints: null,
        is_specifically_time: false,
        duration: 1,
        from_date: "",
        to_date: "",
        name: "",
        is_first_time_program: 1,
      },

      fieldsTable: [
        {
          key: "index",
          label: "STT",
        },
        {
          key: "user_infor.name",
          label: "Tên nhân viên",
        },
        {
          key: "user_infor.email",
          label: "Email",
        },
        {
          key: "member_package.code",
          label: "Gói",
        },
      ],
      durationOptions: [
        { value: 1, text: "6 tháng kể từ ngày bắt đầu", month: 6 },
        { value: 2, text: "12 tháng kể từ ngày bắt đầu", month: 12 },
        { value: 3, text: "24 tháng kể từ ngày bắt đầu", month: 24 },
      ],
      realData: {
        moneyValue: null,
        memberPoints: null,
      },
      configData: {},
    };
  },
  computed: {
    pointsValue() {
      const points = this.realData.moneyValue / (this.configData?.rate || 0);

      return {
        round: this.roundToFourDecimals(points),
        value: points,
      };
    },
  },
  created() {
    this.getExchangeRateByOrgId();
  },
  methods: {
    handleSelectedMembers(data) {
      this.selectedMembers = data || [];
    },
    async getExchangeRateByOrgId() {
      try {
        const userData = appUtils.getLocalUser();

        if (!userData.supplierInfo?.org_id) return;
        const org_id = userData.supplierInfo?.org_id;

        const response = await this.$rf
          .getRequest("CommonRequest")
          .getExchangeRateDetail(org_id);

        this.configData = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    roundToFourDecimals(number) {
      if (!number) return "";
      const isInteger = Number.isInteger(number);

      let result;
      if (isInteger) {
        result = appUtils.numberFormat(number);
      } else {
        const formatNumber = parseFloat(number).toFixed(4);
        const splitNumber = formatNumber.split(".");
        const intNumber = appUtils.numberFormat(splitNumber[0]);
        result = `${intNumber}.${splitNumber[1]}`;
      }

      return result;
    },

    handleChangeDoctorPoints(value) {
      const newValue = Number(value?.toString()?.replaceAll(",", ""));
      this.form.memberPoints = appUtils.numberFormat(newValue);
      this.realData.memberPoints = newValue;

      this.form.moneyValue = this.roundToFourDecimals(
        newValue * this.configData.rate
      );

      this.realData.moneyValue = newValue * this.configData.rate;
    },
    handleChangeMoneyValue(value) {
      const newValue = Number(value?.toString()?.replaceAll(",", ""));
      this.form.moneyValue = appUtils.numberFormat(newValue);

      this.realData.moneyValue = newValue;

      this.form.memberPoints = this.roundToFourDecimals(
        newValue / this.configData.rate
      );

      this.realData.memberPoints = newValue / this.configData.rate;
    },
    async handleDepositPoints() {
      try {
        const userData = appUtils.getLocalUser();

        if (!userData.supplierInfo?.org_id) return;

        const isValid = this.handleCheckValid();
        if (!isValid) return;

        const params = {
          expired_rule: this.form.is_specifically_time
            ? EXPIRED_RULE_CREDIT.prefixReset
            : EXPIRED_RULE_CREDIT.loopReset,
          money_amount: this.realData.moneyValue,
          point: this.pointsValue.value,
          exchange_rate: this.configData.rate,
          org_id: userData.supplierInfo?.org_id,
          name: this.form.name,
          type: HODO_CREDIT_TYPE.deposit,
          start_date: this.form.from_date
            ? window.moment(this.form.from_date).valueOf()
            : undefined,
          expired_date: this.handleGetExpiredDate(),
          is_first_time: this.form.is_first_time_program,
        };
        const response = await this.$rf
          .getRequest("CommonRequest")
          .createPointProgram(params);

        if (response.status === 200) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("Nạp điểm thành công"),
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.$router.push({ name: "deposit" });
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleGetExpiredDate() {
      if (this.form.is_specifically_time) {
        return this.form.to_date
          ? window.moment(this.form.to_date).valueOf()
          : undefined;
      } else {
        const month =
          this.durationOptions.find((item) => item.value === this.form.duration)
            ?.month || 0;

        return window.moment(this.form.to_date).add(month, "month").valueOf();
      }
    },
    handleCheckValid() {
      const requiredForm = omit(this.form, [
        "to_date",
        "duration",
        "is_specifically_time",
      ]);

      const isValidForm = Object.values(requiredForm).every((item) => item);

      const isValidDuration = Boolean(
        this.form.is_specifically_time === EXPIRED_RULE_CREDIT.prefixReset
          ? this.form.to_date
          : this.form.duration
      );

      if (!isValidForm || !isValidDuration) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("Vui lòng nhập đầy đủ dữ liệu"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });

        return false;
      }

      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.container-pointsValue {
  background: #fff;
}
</style>